.contact-form-wrapper {
  position: relative;
  height: calc(100vh - 75px);
  .container {
    width: 60%;
    max-width: 100%;
    position: absolute;
    bottom: -5%;
    left: 20%;
    &::after {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-image: url("../../../assets/images/contact/contactform-bcg.png");
      background-size: cover;
      background-position: center;
      opacity: 0.5;
      z-index: -1;
    }
  }
  .contact-back-btn {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 5px;
    transition: ease-out 0.15s;
    cursor: pointer;
    width: 90px;
    color: #000;
    &:hover {
      gap: 15px;
      transition: ease-in 0.15s;
    }
  }
  p {
    font-size: 50px;
    font-weight: 500;
    line-height: 85px;
  }
  .contact-message {
    display: flex;
    flex-direction: column;
    gap: 20px;
    input,
    .ant-select-selector {
      font-size: 20px;
      font-weight: 400;
      border: none;
      border-radius: 10px;
      padding: 15px 20px;
      outline: none;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
    }
    .service-select option {
      height: 100px;
      overflow-y: auto;
      ::-webkit-scrollbar {
        width: 8px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: red;
        border-radius: 4px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background-color: #000;
      }
    }
  }
  .contact-button {
    // margin-top: 25px;
    display: flex;
    justify-content: flex-end;
    button {
      background-color: #ffcc29;
      border-radius: 16px;
      padding: 15px 80px;
      text-align: center;
      border: none;
      border: 3px solid #ffcc29;
      font-size: 25px;
      font-weight: 600;
      cursor: pointer;
      color: #000;
      &:hover {
        color: #fff;
        transition: all 0.3s ease;
        border: 3px solid #ffcc29;
      }
    }
  }
  .main-p {
    margin-bottom: 20px;
  }
}
.mobile-contact-image {
  display: none;
}
.ant-select-single {
  width: 100% !important;
  height: 55px !important;
}
.ant-space-item {
  width: 100% !important;
}
@media only screen and (min-width: 1537px) {
  .contact-form-wrapper {
    .container {
      bottom: 6%;
    }
    .contact-back-btn {
      font-size: 20px;
    }
    p {
      font-size: 70px;
    }
    .contact-message input {
      font-size: 30px;
    }
    .ant-select .ant-select-selector .ant-select-selection-placeholder {
      font-size: 30px;
    }
    .contact-button button {
      font-size: 30px;
    }
  }
  .ant-select-dropdown .ant-select-item-option-content {
    font-size: 20px;
  }
}
@media (max-width: 978px) {
  .contact-form-wrapper {
    p {
      font-size: 30px;
      text-align: center;
    }
    .container {
      background-color: #f1f1f1;
      border-radius: 10px;
      padding: 30px;
    }
    .contact-button {
      display: block;
      button {
        width: 100%;
      }
    }
    .main-p {
      margin-bottom: 20px !important;
    }
  }
}
.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: #000 !important;
  box-shadow: none !important;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #ffcc29 !important;
}
.ant-select .ant-select-selector .ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.7) !important;
  opacity: 1 !important;
}
.contact-button button:disabled {
  cursor: not-allowed;
}
@media (max-width: 450px) {
  .contact-back-btn {
    display: none !important;
  }
  .mobile-contact-image {
    position: relative;
    display: block;
    img {
      width: 100%;
      height: 40vh;
      object-fit: cover;
    }
    .mobile-contact-overlay {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
  .contact-form-wrapper .container::after {
    display: none !important;
  }
}
.ant-select.ant-select-outlined.select.css-dev-only-do-not-override-11lehqq.ant-select-single.ant-select-show-arrow:hover {
  border-radius: 10px;
  outline: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
}
.ant-select-dropdown {
  max-height: 100px;
  overflow-y: auto;
}
.ant-select-dropdown::-webkit-scrollbar {
  width: 8px;
  height: 50px;
}
.ant-select-dropdown::-webkit-scrollbar-thumb {
  background-color: #ffcc29;
  border-radius: 10px;
}
.ant-select-dropdown::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #d9d9d9;
}
.countrycode {
  margin-bottom: 0;
  line-height: 35px;
  font-size: 20px;
  position: absolute;
  z-index: 11;
  left: 2%;
  bottom: -1%;
  display: flex;
  align-items: center;
  height: 100%;
  padding-bottom: 0;
  color: #838383;
}
.phone-input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
