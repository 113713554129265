.service {
  width: 100%;
  height: 100px;
  background-color: #fff;
  border-radius: 20px;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  align-items: center;

  &_head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_label {
      font-size: 32px;
      font-weight: 500;
      transition: ease-out 0.15s;
    }

    &_arrow {
      transform: rotate(-90deg);
      margin-right: 15px;
      transition: ease-out 0.15s;
    }
  }

  &:hover &_head_label {
    color: #ffcc29;
    transition: ease-in 0.15s;
  }
  &:hover &_head_arrow {
    margin-right: 0;
    transition: ease-in 0.15s;
  }
}

@media (max-width: 1410px) {
  .service {
    &_head {
      &_label {
        font-size: 28px;
        font-weight: 500;
        transition: ease-out 0.15s;
      }
    }
  }
}

@media (max-width: 1200px) {
  .service {
    padding: 20px;
    min-height: 100px;
    gap: 8px;
    height: unset;
    &_head {
      &_label {
        font-size: 24px;
        font-weight: 500;
        transition: ease-out 0.15s;
      }

      &_arrow {
        width: 25px;
        height: 25px;
      }
    }
  }
}

@media (max-width: 780px) {
  .service {
    min-height: unset;
    gap: 5px;
    box-shadow: 0px 4px 4px 0px #00000040;
    &_head {
      &_label {
        font-size: 20px;
      }

      &_arrow {
        width: 18px;
        height: 18px;
      }
    }
  }
}
