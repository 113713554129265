.about-section {
  position: relative;
  .about {
    display: flex;
    justify-content: center;
    line-height: 20px;
    backdrop-filter: blur(5px);
    &::after {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-image: url(../../assets/images/about/background.png);
      background-size: cover;
      background-position: center;
      backdrop-filter: blur(5px);
      opacity: 0.5;
      z-index: -1;
    }
    .container {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      margin-top: 170px;
      .logo {
        width: 26rem;
      }
      .mb-hidden {
        display: block;
      }
      .mb-block {
        display: none;
      }
      .content {
        background-color: #fdfdfd;
        border-radius: 1.5rem;
        border: 1px solid #00000040;
        padding: 3.5rem;
        padding-bottom: 3rem;
        width: 100%;
        margin-bottom: 30px;
        box-shadow: 0px 2px 10px 0px #00000040;
        .about-content {
          font-size: 20px;
        }
        .text {
          padding: 3rem 0;
        }
        ol {
          margin-left: 1rem;
        }
        .bold {
          font-family: Montserrat-600;
          font-size: 18px;
        }
        .companies {
          display: flex;
          gap: 2rem;
          .company {
            width: 19rem;
            display: flex;
            flex-direction: column;
            gap: 0.7rem;
            p {
              font-size: 20px;
              font-family: Montserrat-600;
              font-weight: 600;
            }
            .logo-box {
              background-color: #ffffff;
              border-radius: 1rem;
              box-shadow: 1px 1px 4px 0px #00000040;
              padding: 0.3rem;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 11rem;
              img {
                width: 11rem;
              }
            }
            small {
              font-size: 14px;
              a {
                color: black;
                text-decoration: underline;
              }
            }
            .btn-box {
              display: flex;
              justify-content: flex-end;
              .btn {
                border: 3px solid #ffcc29;
                border-radius: 6px;
                background-color: transparent;
                font-size: 1rem;
                font-family: Montserrat-600;
                padding: 0.4rem 2rem;
                transition: 0.3s;
                cursor: pointer;
                color: #000;
                &:hover {
                  background-color: #ffcc29;
                }
              }
            }
          }
        }
        .letters {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
          grid-gap: 3rem;

          .letter-box {
            display: flex;
            flex-direction: column;
            gap: 0.7rem;

            .letter {
              width: 100%;
              border: 5px solid #ffcc29;
              border-radius: 3px;

              img {
                width: 100%;
              }
            }

            span {
              text-align: center;
              font-size: 11px;
              font-family: Montserrat-600;
            }
          }
        }
      }
      .events {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        .event {
          display: flex;
          align-items: center;
          gap: 2rem;
          .detail {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .date {
              font-family: Montserrat-600;
              text-align: right;
            }
            .description {
              font-size: 18px;
              font-weight: 400;
              text-align: justify;
            }
          }
        }
      }
    }
  }
}

/* slick-slider */
.slider-container {
  width: 400px;
  img {
    width: 400px !important;
    border-radius: 10px;
    height: 320px;
    object-fit: cover;
  }
  .slick-prev:before,
  .slick-next:before {
    margin-left: -70px !important;
  }
  .slick-prev,
  .slick-next {
    z-index: 1 !important;
    margin-left: 70px !important;
  }
}
@media only screen and (min-width: 1367px) {
  .about-section {
    .about {
      .container {
        .logo {
          width: 28rem;
        }
        .content {
          .about-content {
            font-size: 20px;
          }
          .text {
            font-size: 22px;
          }
          .companies {
            .company {
              width: 330px;
              p {
                font-size: 20px;
                font-family: Montserrat-600;
                font-weight: 600;
              }
              small {
                font-size: 18px;
              }
              .btn-box {
                .btn {
                  font-size: 20px;
                }
              }
            }
          }
          .events {
            .event {
              .detail {
                .date {
                  font-size: 26px;
                }
                .description {
                  font-size: 24px;
                  line-height: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
  .slider-container {
    width: 550px;
    img {
      width: 550px !important;
      border-radius: 10px;
      height: 480px;
    }
    .slick-prev:before,
    .slick-next:before {
      margin-left: -70px !important;
      width: 35px !important;
    }
    .slick-prev,
    .slick-next {
      z-index: 1 !important;
      margin-left: 70px !important;
      width: 35px !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .about-section {
    .about {
      &::after {
        height: 40vh;
      }
      .container {
        width: 100%;
        .logo {
          width: 19rem;
        }
        .content {
          padding: 2.5rem;
          .text {
            padding: 2rem 0;
          }
          .companies {
            flex-direction: column;
            .company {
              width: 100%;
            }
          }
          .events {
            .event {
              flex-direction: column;
              align-items: flex-start;
              gap: 1rem;
              .detail {
                .date {
                  font-family: Montserrat-600;
                  text-align: left;
                }
              }
            }
          }
          .letters {
            grid-gap: 2rem;
          }  
        }
      }
    }
    .slider-container {
      width: 330px;
      height: 270px;
      max-width: 100%;
      img {
        width: 360px !important;
        height: 270px;
        max-width: 100%;
        border-radius: 0px;
        object-fit: cover;
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .about-section {
    .about {
      .container {
        width: 100%;
        .mb-hidden {
          display: none;
        }
        .center-logo {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 1rem;
          .logo {
            width: 14rem;
          }
          .mb-block {
            display: block;
          }
        }
        .content {
          padding: 1.5rem;
          .letters {
            grid-gap: 1rem;
          }  
        }
      }
    }
    .slider-container {
      width: 100%;
      img {
        border-radius: 0px;
      }
    }
  }
}
.pro{
  min-height: calc(100vh - 250px);
  .content{
    height: 100%;
  }
}