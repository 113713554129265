.service_side {
  display: flex;
  flex-direction: column;
  gap: 70px;

  &_head_label {
    font-size: 70px;
    font-weight: 500;
    line-height: 85px;
  }

  &_container {
    display: grid;
    grid-template-columns: 49% 49%;
    column-gap: 2%;
    row-gap: 25px;

    &_link {
      text-decoration: none;
      color: inherit;
    }
  }

  &_loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      border-radius: 10px;
    }
  }
}

@media (max-width: 1410px) {
  .service_side {
    gap: 55px;

    &_head_label {
      font-size: 60px;
      line-height: 65px;
    }
  }
}

@media (max-width: 1050px) {
  .service_side {
    gap: 45px;

    &_head_label {
      font-size: 45px;
      line-height: 45px;
    }

    &_container {
      grid-template-columns: 100%;
      column-gap: unset;
      row-gap: 25px;
    }
  }
}

@media (max-width: 780px) {
  .service_side {
    gap: 35px;
    padding: 30px 30px 80px;
    background-color: #f1f1f1;
    border-radius: 34px;
    box-shadow: 0px 4px 4px 0px #00000040;
    margin-top: -40px;

    &_head_label {
      text-align: center;
      font-size: 30px;
      line-height: 35px;
    }

    &_container {
      row-gap: 15px;
    }
  }
}
.no_results_message {
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
}
