.service_category {
  width: 100%;

  &_name {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: ease-out 0.15s;

    &:hover {
      color: #ffcc29;
      transition: ease-in 0.15s;
    }
  }

  &_container {
    list-style: none;
    display: flex;
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
    transition: .150s ease-out;

    &_element {
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      transition: ease-out 0.15s;
      padding: 5px 5px 5px 20px;

      &:hover {
        color: #ffcc29;
        transition: ease-in 0.15s;
      }
    }
  }

  &_active_container {
    max-height: 150px;
    overflow-y: scroll;
    transform: translateY(0);
    transition: .150s ease-in;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #ffcd2943; 
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ffcc29;
      border-radius: 10px;
    }
  }
}


.active_service_name {
  color: #ffcc29 !important;
}