* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat";
}
@font-face {
  font-family: Montserrat;
  src: url(./assets/fonts/Montserrat-VariableFont_wght.ttf) format("truetype");
}
@font-face {
  font-family: Montserrat-600;
  src: url(./assets/fonts/Montserrat-SemiBold.ttf);
}
@font-face {
  font-family: Montaga;
  src: url(./assets/fonts/Montaga-Regular.ttf);
}

@media (max-width: 768px) {
  .header-logo img {
    width: 170px !important;
  }
}

.dropdown-content {
  padding-left: 34px;
}

.simplebar-track.simplebar-vertical {
  right: unset;
  left: 0;
  width: 4px !important;
  background-color: #ffffff;
  border-radius: 10px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar {
  background-color: #e7b921;
  width: 100%;
  border-radius: 10px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
  background-color: #e7b921 !important;
}
