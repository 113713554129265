.faq {
  position: relative;
  min-height: calc(100vh - 75px);
  .bg-container {
    display: flex;
    margin-left: 12%;
    min-height: calc(100vh - 75px);
    .content-container {
      width: 90%;
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      margin-bottom: 1rem;
      margin-top: 200px;
      position: relative;
      &::after {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-image: url(../../assets/images/background-faq.png);
        background-size: cover;
        background-position: center;
        opacity: 0.5;
        z-index: -1;
      }
      h1 {
        font-size: 45px;
        font-weight: 500;
      }
      .content {
        display: flex;
        align-items: flex-start;
        gap: 1.5rem;
        min-height: 300px;
        .accordion {
          width: 75%;
          min-height: 300px;
        }
      }
    }
    .btn-box {
      .btn {
        background-color: #ffcc29;
        border-radius: 7px;
        padding: 12px;
        width: 100%;
        border: none;
        font-size: 22px;
        min-width: 300px;
        font-weight: 600;
        border: 3px solid #ffcc29;
        cursor: pointer;
        transition: 0.3s;
        color: #000;
        &:hover {
          color: #fff;
        }
      }
    }
  }
}

@media only screen and (min-width: 1367px) {
  .faq {
    min-height: calc(100vh - 100px);
    .bg-container {
      min-height: calc(100vh - 75px);
      .content-container {
        h1 {
          font-size: 60px;
        }
      }
      .btn-box {
        .btn {
          font-size: 26px;
          width: 100%;
          min-width: 364px;
        }
      }
    }
  }
}
@media only screen and (max-width: 998px) {
  .faq {
    min-height: calc(100vh - 130px);
    .bg-container {
      min-height: calc(100vh - 130px);
    }
  }
}

@media only screen and (max-width: 768px) {
  .faq {
    min-height: calc(100vh - 130px);
    .bg-container {
      justify-content: center;
      margin-left: 0;
      min-height: calc(100vh - 130px);
      .content-container {
        background-color: #f1f1f1;
        padding: 20px 20px 40px;
        border-radius: 20px;
        margin-top: 169px;
        gap: 1rem;
        width: 100%;
        box-shadow: 0px 2px 10px 0px #00000040;
        h1 {
          font-size: 35px;
          text-align: center;
        }
        .content {
          flex-direction: column;
          .accordion {
            width: 100%;
          }
        }
      }
      .btn-box {
        width: 100%;
        .btn {
          width: 100%;
        }
      }
    }
  }
}
@media only screen and (max-width: 425px) {
  .faq {
    min-height: calc(100vh - 130px);
    .bg-container {
      min-height: calc(100vh - 130px);
      .content-container {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
}
